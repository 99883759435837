import React, { useEffect, useState } from "react"

import Client from "shopify-buy"
import Context from "~/context/StoreContext"
import fetch from "isomorphic-fetch"

const client = Client.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
  },
  fetch
)

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    updating: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    isPromoBannerOpen: true,
  }

  const [store, updateStore] = useState(initialStoreState)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("shopify_checkout_id")
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem("shopify_checkout_id", checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout }
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem("shopify_checkout_id", null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [store.client.checkout, isRemoved])

  useEffect(
    () => () => {
      setIsRemoved(true)
    },
    []
  )

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity) => {
          const { checkout, client } = store
          const checkoutId = checkout.id
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ]
          
          if (variantId === "" || !quantity) {
            console.error("Both a size and quantity are required.")
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false }
              })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]

          updateStore(prevState => {
            return { ...prevState, updating: true }
          })

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res, updating: false }
              })
            })
        },
        isProductAvailable: async (productId) => {
          const productsQuery = client.graphQLClient.query((root) => {
            root.addConnection('products', { args: { first: 1, query: `id:${productId}`}}, (product) => {
              product.add('title');
              product.add('availableForSale');
            })
          });

          let isAvailable = client.graphQLClient.send(productsQuery).then(({model, data}) => {
            // console.log(JSON.stringify(product, null, 4));
            // console.log(model.products[0].availableForSale);
            return model.products[0].availableForSale;
          });

          return isAvailable;

        },
        isCartOpen,
        setIsCartOpen,
        setIsPromoBannerOpen: isOpen => {
          updateStore(prevState => {
            return { ...prevState, isPromoBannerOpen: isOpen }
          })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
