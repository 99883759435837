import React from "react"

function Wave({position, color}, props) {
  return (
    <svg className={`wave wave--${position}`} width={1288} height={24} preserveAspectRatio="none" viewBox="0 0 1288 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1288 10.286l-71.91 3.428C1145.25 17.143 1001.42 24 858.667 24c-142.754 0-286.58-6.857-429.334-10.286-142.753-3.428-286.58-3.428-357.42-3.428H0V0H1288v10.286z"
        fill={color}
      />
    </svg>
  )
}

export default Wave
