import React, { useState, useRef, useEffect } from "react"

const BookModal = ({texts}) => {
  const [email, setEmail] = useState("")
  const [subscribe, setSubscribe] = useState(false)
  const [formSubmited, setFormSubmited] = useState(false)
  const [noErrorMail, setNoErrorMail] = useState(null)
  const botInput = useRef(null);
  const mail = useRef(null);

  const checkMail = () => {
    if(!!email){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
         return false;
      }
      return true;
    } 

    return false;
  }

  const onSubmit = e => {
    e.preventDefault()
    const formEl = e.target

    if(botInput.current.value === ''){
      console.log('send email :) ');
      fetch("https://funky.buddybuddy.io/wp-json/contact-form-7/v1/contact-forms/13741/feedback", {
        method: "POST",
        body: new FormData(formEl),
      })
        .then(response => {
          if (response.ok) {
            setFormSubmited(true)
          }
        })
        .catch(error => {
          console.warn(error)
        })
      
        // if subscribe to newsletter 
        if(subscribe){
          fetch("https://funky.buddybuddy.io/wp-json/contact-form-7/v1/contact-forms/13861/feedback", {
            method: "POST",
            body: new FormData(formEl),
          })
            .then(response => {
              console.log(response);
              if (response.ok) {
                console.log('ok newsletter too');
              }
            })
            .catch(error => {
              console.warn(error)
            })
        }

    }else {
      // console.log('bot alert !! ');
    }
    
  }

  useEffect(() => {
    console.log('setSubscribe => ',subscribe);
  }, [subscribe])

  return (
    <>
      <h1>{texts.popEbook.title}</h1>
      {!formSubmited ? (
        <>
          <p dangerouslySetInnerHTML={{ __html: texts.popEbook.text }} />
          <form
            className="ebook-form"
            name="ebook-form"
            autoComplete="off"
            onSubmit={e => onSubmit(e)}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            {/* <input type="hidden" name="ebook-form" value="ebook-form" /> */}
            <div hidden>
              <label>
                Don’t fill this out:
                <input ref={botInput} name="bot-field" />
              </label>
            </div>
            <div className="field-wrapper">
              <input
                id="email"
                type="email"
                name="your-email"
                ref={mail}
                required
                autoComplete="off"
                className={email ? "filled" : ""}
                onChange={e => checkMail(e.target.value)}
                onInput={e => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email*</label>
            </div>
            {noErrorMail !== null && !noErrorMail && (
              <div className="error">Votre email n'est pas correcte !</div>
            )}
            <div className="field-wrapper checkbox">
              <input
                id="subscribe"
                type="checkbox"
                name="mailjet-opt-in"
                defaultChecked={subscribe}
                autoComplete="off"
                onChange={e => setSubscribe(e.target.checked)}
                className={subscribe ? "filled" : ""}
              />
              <label htmlFor="subscribe">S'inscrire à la newsletter</label>
            </div>
            <div className="field-wrapper">
              <button
                className={"link link--black"}
                type="submit"
                disabled={!email}
              >
                Je le veux !
              </button>
            </div>
          </form>
        </>
      ) : (
        <p style={{ paddingBottom: "32px" }}>
          <strong>Demande reçue.</strong>
        </p>
      )}
    </>
  )
}

export default BookModal
