import React, { useEffect, useState } from "react"

import PostCard from "./post-card/post-card.js"
import ProductCard from "./product-card/product-card.js"
import StripeText from "../decoration/stripe-text.js"
import algoliasearch from "algoliasearch/lite"
import throttle from "react-throttle-render"

const algoliaIndex = process.env.GATSBY_ALGOLIA_INDEX_NAME
const appId = process.env.GATSBY_ALGOLIA_APP_ID
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY

const client = algoliasearch(appId, searchKey)
const index = client.initIndex(algoliaIndex)

const PostsList = ({ searchQuery }) => {
  const [productHits, setProductHits] = useState([])
  const [postHits, setPostHits] = useState([])

  useEffect(() => {
    index.search(searchQuery).then(({ hits }) => {
      setProductHits(hits.filter(hit => hit.type === "WpProduct_Product"))
      setPostHits(hits.filter(hit => hit.type === "WpPost"))
    })
  }, [searchQuery])

  return (
    <div className="posts-list">
      <div className="posts-list__group">
        <div className="posts-list__header">
          <StripeText text={"PRODUITS"} color="#e7004c" />
          <span className="posts-list__counter">
            ({productHits.length ? productHits.length : "0"})
          </span>
        </div>
        {!!productHits &&
          productHits.map(hit => {
            const { objectID } = hit
            return <ProductCard key={objectID} product={hit} />
          })}
      </div>
      <div className="posts-list__group">
        <div className="posts-list__header">
          <StripeText text={"ARTICLES"} color="#e7004c" />
          <span className="posts-list__counter">
            ({postHits.length ? postHits.length : "0"})
          </span>
        </div>
        {!!postHits &&
          postHits.map(hit => {
            const { objectID } = hit
            return <PostCard key={objectID} post={hit} />
          })}
      </div>
    </div>
  )
}

export default throttle(500)(PostsList)
