import { useEffect, useState } from "react"

import { throttle } from "lodash"

function useDocumentScrollThrottled(callback) {
  const [, setScrollPosition] = useState(0)
  let previousScrollTop = 0

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } =
      typeof window === "undefined" || !window.document
        ? 0
        : document.documentElement || document.body

    setScrollPosition(previousPosition => {
      previousScrollTop = previousPosition
      return currentScrollTop
    })

    callback({ previousScrollTop, currentScrollTop })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250) // => 30fps || 16: 60fps

  useEffect(() => {
    window.addEventListener("scroll", handleDocumentScrollThrottled, {passive: true})

    return () =>
      window.removeEventListener("scroll", handleDocumentScrollThrottled)
  }, [handleDocumentScrollThrottled])
}

export default useDocumentScrollThrottled
