/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "~/styles/all.scss"

import Cart from "~/components/shop/cart/cart.js"
import ContextProvider from "~/provider/ContextProvider"
import Footer from "~/components/footer/footer.js"
// import Gdpr from "~/components/gdpr/gdpr.js"
import Nav from "~/components/nav/nav.js"
import { OptionDuTheme } from "../../hooks/site-options"
import PromoBanner from "../../components/nav/promo-banner.js"
import React from "react"

const Layout = ({ location, children }) => {
  const options = OptionDuTheme()
  const promoBannerContent = options.optionsDuTheme.options_content.promobanner

  return (
    <ContextProvider>
      {!!options.optionsDuTheme.options_content.promobanner && (
        <PromoBanner content={promoBannerContent} />
      )}
      <Nav location={location} isWhite={location.pathname === "/"} />
      <Cart />
      <main>{children}</main>
      <Footer />
      {/* <Gdpr /> */}
      <div id="modal-root" />
    </ContextProvider>
  )
}

export default Layout
