import Cross from "../../images/icons/cross.svg"
import React from "react"
import ReactDOM from "react-dom"

const Modal = React.memo(({ children, closeModal, optClassName }) => {
  const domEl = document.getElementById("modal-root")

  if (!domEl) return null

  return ReactDOM.createPortal(
    <>
      <div
        className={"modal-overlay"}
        role="button"
        aria-label="Fermer la modale"
        tabIndex={-1}
        onKeyDown={closeModal}
        onClick={closeModal}
      ></div>
      <div
        className={`modal-wrapper ${
          optClassName ? `modal-wrapper--${optClassName}` : ""
        }`}
      >
        <div
          className={`modal-content ${
            optClassName ? `modal-content--${optClassName}` : ""
          }`}
        >
          <button
            className={"modal-close"}
            onClick={closeModal}
            title={"Fermer"}
          >
            <span>Fermer</span>
            <img src={Cross} alt={"X"} width="42" height="42" />
          </button>
          {children}
        </div>
      </div>
    </>,
    domEl
  )
})

export default Modal
