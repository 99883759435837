import React from "react"

const Stripe = ({ color }) => (
  <svg width={73} height={43} viewBox="0 0 73 43" fill="none" preserveAspectRatio='none'>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.994 13.393c-.031.698-.185 1.309-.409 1.643l-1.449 2.155.657 1.66c.188.472.284 1.159.26 1.846-.023.69-.166 1.305-.384 1.663l-.623 1.02.581.757c.27.357.451 1.092.464 1.91.013.852-.14 1.59-.398 1.996l-1.139 1.773.82 3.126c.177.667.203 1.546.07 2.276-.133.73-.407 1.196-.706 1.2l-.264.002.394 3.796c.071.659.022 1.365-.126 1.912-.148.547-.382.87-.631.872l-68.446-.05c-.326.007-.625-.529-.753-1.352-.13-.827-.063-1.777.166-2.411l1.503-4.132-1.538-2.186c-.233-.333-.394-.952-.431-1.663-.039-.715.053-1.422.244-1.908l1.206-3.078L.446 23.98c-.292-.408-.467-1.256-.444-2.15.026-.895.245-1.652.556-1.915l1.328-1.125-.672-1.588c-.19-.448-.295-1.11-.281-1.794.013-.683.141-1.31.347-1.692l.316-.59-1.13-2.548C.228 10.043.122 9.184.191 8.374c.068-.819.3-1.449.598-1.635l2.159-1.35-.38-2.297c-.113-.68-.093-1.466.052-2.084C2.765.38 3.014.007 3.285 0l65.907.103c.1-.003.203.053.296.15l2.269 2.4c.265.277.46.925.51 1.688.05.767-.052 1.545-.266 2.047l-.647 1.503 1.394 3.624c.156.408.247.971.252 1.562 0 .104-.002.208-.006.316z"
      fill={color}
    />
  </svg>
)

export default Stripe
