import React from "react"

function IconClose({color, size}) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <path
        d="M24 8L8 24M8 8l16 16"
        stroke={color}
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconClose
