import React from "react"
import Stripe from "./stripe.js"

const StripedText = ({ className, text, color }) => {
  return (
    <div className={`stripe-text ${className ? className : ""}`}>
      <div className="stripe-text__content">
        <Stripe color={color} />
        <span>{text}</span>
      </div>
    </div>
  )
}
export default StripedText
