import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

function SEO({ meta, type, image }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //         }
  //       }
  //     }
  //   `
  // )

  // const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet>
      <meta property="og:locale" content="fr_FR" />
      <meta name="robots" content="index, follow" />
      <meta name="facebook-domain-verification" content="3fz9rmvc0d5qjptbhzss2ha1lm7dfu" />

      {!!meta && meta.title && (
          <title>{meta.title}</title>
      )}

      {!!meta && meta.metaDesc && 
          <meta name="description" content={meta.metaDesc} />
      }

      {!!meta && meta.opengraphTitle && 
          <meta property="og:title" content={meta.opengraphTitle} />
      }

      {!!meta && meta.opengraphType && 
          <meta property="og:type" content={!!type ? type : meta.opengraphType}/>
      }

      {!!meta && meta.opengraphUrl && 
          <meta property="og:url" content={meta.opengraphUrl}/>
      }

      {!!image && 
          <meta property="og:image" content={image}/>
      }

      {!!meta && meta.opengraphPublisher && 
          <meta property="article:publisher" content={meta.opengraphPublisher} />
      }

      {!!meta && meta.opengraphPublishedTime && 
          <meta property="article:published_time" content={meta.opengraphPublishedTime} />
      }
      
      {!!meta && meta.opengraphModifiedTime && 
          <meta property="article:modified_time" content={meta.opengraphModifiedTime} />
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  type: 'website',
  image: null
}

export default SEO