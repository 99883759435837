import React, { useContext, useEffect, useState } from "react"
import { ROUTES } from "../../../../constants.js"
import { OptionDuTheme } from "../../../hooks/site-options"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation } from "swiper"

import head from "lodash/head"
import Img from "../../../helpers/img"
import LineItem from "~/components/shop/cart/line-item/line-item"
import SsrCookie from "ssr-cookie"
import shuffle from "lodash/shuffle"
import CloseIcon from "../../../images/icons/close.svg"
import StoreContext from "~/context/StoreContext"
import getMergeProducts from "~/helpers/products/getMergedProducts.js"
import useWindowSize from "~/helpers/hooks/useWindowSize.js"

SwiperCore.use([Autoplay, Navigation])
const cookie = new SsrCookie()

const Cart = () => {
  // Context
  const {
    isCartOpen,
    setIsCartOpen,
    addVariantToCart,
    store: { checkout, adding },
  } = useContext(StoreContext)

  // State
  const [upSellProducts, setUpSellProducts] = useState([])

  // Hooks
  const { isMobile } = useWindowSize()

  // Lifecycle
  useEffect(() => {
    const products = getMergeProducts(
      shuffle(productsDatas.allWpProduct.nodes),
      shuffle(productsDatas.allShopifyProduct.nodes)
    )
    const filteredProducts = products
      .filter(product => {
        const { shopifyVariants, shopifyAvailableForSale } = head(product)
        const variant = head(shopifyVariants)
        const isAvailableForSale = variant?.availableForSale;
        const variantId = variant.shopifyId
        const inCart = !!checkout.lineItems.find(
          item => variantId === item.variant.id
        )
        // console.log({variant, isAvailableForSale, inCart, product})
        // return isAvailableForSale === true && !inCart // le shopifyAvailable
        return !inCart;
      })
      .slice(0, 3)
    setUpSellProducts(filteredProducts)
  }, [checkout.lineItems])

  useEffect(() => {
    if (isCartOpen && isMobile) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isCartOpen, isMobile])

  const handleCheckout = () => {
    // get url
    let url = checkout.webUrl
    url = url.replace(
      "https://funky-veggie.myshopify.com/",
      "https://shop.funkyveggie.fr/"
    )
    window.open(url)
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} isCartOpen={isCartOpen} />
  ))

  const productsNumber = checkout.lineItems.length

  // Free shipping
  const options = OptionDuTheme()
  const Shipping = options.optionsDuTheme.options_content.shipping
  const CartSliderTexts = options.optionsDuTheme.options_content.cartTexts

  // TODO: Meme requete que pour Featured products / possible factoriser
  const productsDatas = useStaticQuery(
    graphql`
      {
        allWpProduct {
          nodes {
            slug
            uri
            Product {
              fieldGroupName
              productColor
              productId
              productName
              variantName
              productImage {
                sourceUrl
                altText
              }
            }
          }
        }
        allShopifyProduct {
          nodes {
            id
            shopifyId
            description
            title
            availableForSale
            productType
            variants {
              price
              shopifyId
            }
          }
        }
      }
    `
  )

  return (
    <>
      <div
        className={`cart ${isCartOpen ? "cart--open" : ""} ${
          adding ? "cart--adding" : ""
        }`}
      >
        <div className="cart-spinner">
          <span />
        </div>
        <div className="cart-head">
          <h3>
            Panier
            <span>{`${productsNumber}  Article${
              productsNumber && productsNumber > 1 ? "s" : ""
            }`}</span>
          </h3>
          <button
            onClick={() => setIsCartOpen(!isCartOpen)}
            className={"cart-head-button"}
            aria-label="Fermer le menu du panier"
          >
            <img
              className={"cart-head-button-close"}
              src={CloseIcon}
              alt={"X"}
              role="presentation"
              width="32"
              height="32"
            />
          </button>
        </div>
        {!!CartSliderTexts && !!CartSliderTexts.length && (
          <div className="cart-slider">
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation
              autoplay={{
                delay: 3000,
              }}
            >
              {CartSliderTexts.map(({ icon, text }, i) => {
                return (
                  <SwiperSlide className="cart-slide" key={i}>
                    {!!icon &&
                      !!icon.localFile &&
                      !!icon.localFile.publicURL && (
                        <img
                          src={icon.localFile.publicURL}
                          alt=""
                          width="24"
                          height="24"
                        />
                      )}
                    {text && <span>{text}</span>}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        )}

        <div className="cart-center">
          {/* Cart products */}
          <div className="cart-products">
            {productsNumber ? (
              <>{lineItems}</>
            ) : (
              <>
                {!adding && (
                  <div className="cart-empty">Votre panier est vide</div>
                )}
              </>
            )}
          </div>

          {/* Upsells */}
          {!!upSellProducts && !!upSellProducts.length && (
            <div className="cart-upsell">
              <h4 className="cart-upsell__title">Nous vous recommandons:</h4>
              {upSellProducts.map((upSellProduct, i) => {
                const {
                  slug,
                  color,
                  id,
                  image,
                  name,
                  variantName,
                  shopifyVariants,
                  shopifyAvailableForSale,
                } = head(upSellProduct)
                const { price, shopifyId } = head(shopifyVariants)
                return (
                  <div
                    key={id}
                    className="cart-upsell__card"
                  >
                    <Link to={`${ROUTES.SHOP}/${slug}`}>
                      <div
                        className="cart-upsell__card__imageWrapper"
                        style={{ backgroundColor: color }}
                      >
                        {!!image && !!image.sourceUrl && isCartOpen && (
                          <Img
                            source={image.sourceUrl}
                            alt={""}
                            width={200}
                            classe="cart-upsell__card__image"
                          />
                        )}
                      </div>
                      <p className="cart-upsell__card__name">{name}</p>
                      <p className="cart-upsell__card__variantName">
                        {variantName}
                      </p>
                      {/* {shopifyAvailableForSale && ( */}
                        <button
                          className="cart-upsell__card__buy"
                          onClick={e => {
                            e.preventDefault()
                            shopifyId && addVariantToCart(shopifyId, 1)
                            setIsCartOpen(true)
                          }}
                        >
                          Ajouter - {`${price} €`}
                        </button>
                      {/* )} */}
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
        </div>

        {!!productsNumber && (
          <div className="cart-footer">
            {/* <div className="cart-footer-subscribe">
              <div className="cart-footer-subscribe-button">
                <input id="single-checkout" type="radio" name="subscription" defaultChecked={true} />
                <label htmlFor="single-checkout">Achat unique</label>
              </div>
              <div className="cart-footer-subscribe-button">
                <input
                  id="subscribe-checkout"
                  type="radio"
                  name="subscription"
                />
                <label htmlFor="subscribe-checkout">Abonnement</label>
              </div>
            </div> */}
            {/* {console.log('checkout ==> ', checkout)} */}
            <div className="cart-footer-total">
              <span>Total</span>
              <span>{checkout.totalPrice.amount} €</span>
            </div>
            <a
              className="cart-checkout-button"
              // target="_blank"
              // onClick={handleCheckout}
              href={checkout.webUrl.replace(
                "https://funky-veggie.myshopify.com/",
                "https://shop.funkyveggie.fr/"
              )}
              style={{
                pointerEvents:
                  checkout.lineItems.length === 0 ||
                  checkout.totalPrice.amount < Shipping.minimumAmount
                    ? "none"
                    : "auto",
                backgroundColor:
                  checkout.lineItems.length === 0 ||
                  checkout.totalPrice.amount < Shipping.minimumAmount
                    ? "grey"
                    : "black",
              }}
            >
              Procéder au paiement
            </a>
            {checkout.totalPrice.amount < Shipping.minimumAmount ? (
              <div className="cart-footer-progress">
                <div className="cart-footer-progress-bar">
                  <div
                    className="cart-footer-progress-bar-cursor"
                    style={{
                      width: `${
                        (checkout.totalPrice.amount / Shipping.minimumAmount) * 100
                      }%`,
                    }}
                  />
                </div>
                <span className="cart-footer-progress-text">
                  Minimum de commande à{" "}
                  <strong>{Shipping.minimumAmount} €</strong>
                </span>
              </div>
            ) : (
              <div className="cart-footer-progress">
                <div className="cart-footer-progress-bar">
                  <div
                    className="cart-footer-progress-bar-cursor"
                    style={{
                      width: `${
                        (checkout.totalPrice.amount / Shipping.freeShipping) * 100
                      }%`,
                    }}
                  />
                </div>
                <span className="cart-footer-progress-text">
                  Livraison offerte à partir de{" "}
                  <strong>{Shipping.freeShipping} €</strong>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <button className="cart-overlay" onClick={() => setIsCartOpen(false)}>
        <span style={{ opacity: 0 }}>Fermer le panier</span>
      </button>
    </>
  )
}

export default Cart
