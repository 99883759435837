/**
 * - Map des données produits provenant de Shopify et Wordpress:
 */
module.exports = (wpProducts, shopifyProducts) => {
  return shopifyProducts
    .map(shopifyProduct => {

      // let shopifyIdB64 = Buffer.from(shopifyProduct.shopifyId, 'base64').toString('binary');
      // let shopifyId = shopifyIdB64.split('/');
      // shopifyId = shopifyId[shopifyId.length - 1];

      // Regle le probleme de tri sur le composant featured products et affiche les produits du coup...
      let shopifyId = shopifyProduct.shopifyId.split('/');
      shopifyId = shopifyId[shopifyId.length - 1];
      // console.log(shopifyProduct.shopifyId, {shopifyId});

      return wpProducts
        .filter(wpProduct => { 
          // console.log(shopifyId, wpProduct.Product.productId);
          return shopifyId === wpProduct.Product.productId})
        .map(product => {
          return {
            position: product.Product.productPosition,
            color: product.Product.productColor,
            id: product.Product.productId,
            image: product.Product.productImage,
            imageAnimation: product.Product.productAnimation,
            name: product.Product.productName,
            variantName: product.Product.variantName,
            slug: product.slug,
            uri: product.uri,
            shopifyProductType: shopifyProduct.productType,
            shopifyAvailableForSale: shopifyProduct.availableForSale,
            shopifyVariants: shopifyProduct.variants,
            shopifyProductId: shopifyId,
            shopifyTitle: shopifyProduct.title,
            shopifyDescription: shopifyProduct.description,
            product_content: product.product_content,
            product_ranges: product.product_ranges,
            seo: product.seo,
          }
        })
    })
    .filter(p => p[0] != null)
}
