import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

import React from "react"

// import StoreContext from "~/context/StoreContext"

// import Cross from "../../images/icons/cross.svg"

SwiperCore.use([Autoplay])

const PromoBanner = ({ content }) => {
  // const { setIsPromoBannerOpen, store } = useContext(StoreContext)
  return (
    <Swiper
      slidesPerView={1}
      className={"promo-banner"}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {!!content &&
        !!content.length &&
        content.map(({ icon, text, button }, i) => {
          return (
            <SwiperSlide key={i} className={"slide"}>
              {!!icon?.localFile?.publicURL && (
                <img src={icon.localFile.publicURL} alt="" />
              )}
              {text && <p>{text}</p>}
              {!!button && !!button.buttonTitle && 
                <a target="_blank" href={!!button.buttonLink ? button.buttonLink : '#'}>{button.buttonTitle}</a>
              }
              {/* <button
                onClick={() => setIsPromoBannerOpen(false)}
                aria-label="Fermer bandeau promotion"
              >
                <img src={Cross} alt="X" width="30" height="30" />
              </button> */}
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}

export default PromoBanner
