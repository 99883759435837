import { graphql, useStaticQuery } from "gatsby"

export const OptionDuTheme = () => {
  const { wp } = useStaticQuery(
    graphql`
      query OptionsDuTheme {
        wp {
          optionsDuTheme {
            options_content {
              categories {
                descAllproducts
              }
              pressKit
              cartTexts {
                icon {
                  localFile {
                    publicURL
                  }
                }
                text
              }
              shipping {
                freeShipping
                minimumAmount
              }
              promobanner {
                text
                icon {
                  localFile {
                    publicURL
                  }
                }
                button {
                  buttonLink
                  buttonTitle
                }
              }
              eBook {
                buttonTitle
                text
                title
              }
              popEbook {
                text
                title
              }
              ecoconception {
                title
                content
              }
              forms {
                customerService
                joinTheTeam
                messageDeValidation
                reseller
              }
              newsletter {
                text
                title
              }
              rgpd {
                content
                title
              }
              socials {
                facebook
                instagram
                linkedin
                pinterest
              }
              headerNav {
                mainNav {
                  linkAventure {
                    pageLink
                    pageName
                  }
                  linkBlog {
                    pageLink
                    pageName
                  }
                  linkProducts {
                    pageLink
                    pageName
                  }
                  linkShops {
                    pageLink
                    pageName
                  }
                }
                productsNav {
                  cats {
                    cat {
                      name
                      slug
                      description
                      gamme_content {
                        productImage {
                          sourceUrl
                          altText
                        }
                      }
                    }
                    isPack
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wp
}
