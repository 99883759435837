import Img from "../../../helpers/img"
import { Link } from "gatsby"
import React from "react"
import formatDate from "../../../helpers/formatDate.js"

const PostCard = ({ post }) => {
  const { postId, title, date, uri, slug, featuredImage, categories } = post
  const { nodes: category } = categories
  const categoryName = category[0].name
  return (
    <div key={postId} className="post-card">
      {!!featuredImage &&
      !!featuredImage.node &&
      !!featuredImage.node.sourceUrl ? (
        <Img  
          source={featuredImage.node.sourceUrl}
          width={400}
          classe="post-card__image"
          alt={featuredImage?.node?.altText || 'Article blog Funky Veggie'}
        />
      ) : (
        <div className="post-card__image" />
      )}
      {categoryName && <p className="post-card__category">{categoryName}</p>}
      <h3 className="post-card__title">
        <Link to={`/${categories?.nodes?.[0]?.slug}/${slug}`}>{title}</Link>
      </h3>
      <span className="post-card__date">{formatDate(date)}</span>
    </div>
  )
}

export default PostCard
