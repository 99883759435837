import React from "react"

function SearchIcon({color, size}) {
    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
        <path
          d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  
  export default SearchIcon