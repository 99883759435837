module.exports = {
  PREVIOUS_HOSTNAME: 'https://www.funkyveggie.fr',
  NB_POSTS_LIMIT: 100,
  NB_POSTS_PER_PAGE: 11,
  FREE_DELIVERY_THRESHOLD: 35,
  LOGO_PATH: 'static/funky-veggie-logo-new.svg',
  MINIMUM_AMOUNT: 20,
  PRODUCT_TYPES: {
    PACK: 'pack',
    SIMPLE: 'simple',
    GOODIES: 'other',
    BOOK: 'book',
  },
  UNITS: {
    KG: 'KILOGRAMS',
    G: 'GRAMS',
  },
  CATEGORY: {
    "fruits-et-legumes-du-mois": {
      color: "#009036",
    },
    "sante-bien-etre": {
      color: "#DCCE9E",
    },
    diy: {
      // color: "#f39414",
      color: "#ff6b00",
    },
    lifestyle: {
      // color: "#00b1f0",
      color: "#00bed6",
    },
    podcast: {
      // color: "#9f52c1",
      color: "#8347ad",
    },
    recettes: {
      // color: "#FA004C",
      color: "#e7004c",
    },
  },
  DEFAULT_CATEGORY_COLOR: '#333333',
  DEFAULT_PACK_COLOR: '#000000',
  JOB_COLORS: {
    // Old
    // STAGE: "#F39414",
    // CDI: "#00B1F0",
    // CDD: "#9F52C1",
    // ALTERNANCE: "#FA004C",
    // New
    STAGE: "#ff6b00",
    CDI: "#00bed6",
    CDD: "#8347ad",
    ALTERNANCE: "#e7004c",
    DEFAULT: '#333333',
  },
  FORM_TYPES: {
    SERVICE: "service",
    JOIN: "join",
    SELL: "sell",
  },
  CONTACT_TAB: {
    SERVICE: "#0",
    JOIN: "#1",
    SELL: "#2",
  },
  ROUTES: {
    HOME: "/",
    CGV: "/cgv",
    FAQ: "/faq",
    POLICY: "/mentions-legales",
    CONTACT: "/contact",
    DELIVERY: "/livraison",
    LOCATOR: "/magasins",
    SHOP: "/e-shop",
    BLOG: "/leblog",
    CATEGORY: '/category',
    JOIN: "/join",
    ADVENTURE: "/funky_aventure",
    PRESS_KIT:
      "https://drive.google.com/uc?export=download&id=1sw-kLjjMQ_XwJmwZ0Gn6xjf5JfPCyJ4p",
      // "https://drive.google.com/open?id=1p8K0l0xGKwQ4SYIGpaUm-0bW7DuTBIGv",
  },
  SOCIAL: {
    FB: "https://web.facebook.com/funkyveggie",
    INSTA: "https://www.instagram.com/funky_veggie",
    PINTEREST: "https://www.pinterest.fr/funky_veggie",
    LINKED_IN: "https://www.linkedin.com/company/funky-veggie",
  },
}
