import React, { useEffect, useState } from "react"

import Modal from "../../components/modal/modal.js"

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const domEl = document.getElementById("modal-root")
    domEl.setAttribute("style", `display: ${isVisible ? "block" : "none"}`)
    setTimeout(() => {
      domEl.setAttribute("class", `${isVisible ? "shown" : "hidden"}`)
    }, 150) //  Trick to be able to animate the modal with css, on opening...
    document.body.setAttribute(
      "style",
      `overflow: ${isVisible ? "hidden" : "auto"}`
    )
  }, [isVisible])

  const show = () => setIsVisible(true)
  const hide = () => setIsVisible(false)

  const RenderModal = ({ children, optClassName }) => (
    <React.Fragment>
      {isVisible && <Modal optClassName={optClassName} closeModal={hide}>{children}</Modal>}
    </React.Fragment>
  )

  return {
    show,
    hide,
    RenderModal,
  }
}
