import React, { Component } from "react"

import CloseIcon from "./posts-search-icon-close.js"
import PostsList from "../posts-list/posts-list.js"
import SearchIcon from "./posts-search-icon-search.js"

class PostsSearch extends Component {
  constructor() {
    super()
    this.textInput = React.createRef()
  }
  state = {
    searchQuery: "",
    isOpen: false,
  }
  handleSubmit = event => event.preventDefault()
  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  toggleSearch = e => {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        this.state.isOpen && this.textInput.current.focus()
      }
    )
  }
  render() {
    const { state, handleSubmit, handleInputChange, textInput } = this
    const { searchQuery, isOpen } = state
    const { isWhite } = this.props
    return (
      <div className={"posts-search"}>
        <div
          className={`posts-search__wrapper ${
            isOpen ? "posts-search__wrapper--expanded" : ""
          }`}
        >
          <form
            className="posts-search__form container"
            onSubmit={handleSubmit}
          >
            <input
              ref={textInput}
              type="text"
              className="posts-search__field"
              name="searchQuery"
              value={searchQuery}
              onChange={handleInputChange}
              placeholder="Rechercher"
            />
            <button type="button" onClick={e => this.toggleSearch(e)}>
              <CloseIcon color={"#000000"} size={36} />
            </button>
          </form>
        </div>
        <button
          className="posts-search__btn"
          aria-label="Rechercher un article ou un produit"
          onClick={e => this.toggleSearch(e)}
        >
          <SearchIcon color={isWhite ? "#ffffff" : "#000000"} size={36} />
        </button>
        {searchQuery && isOpen && (
          <div className="posts-search__results">
            <p className="mention">Search powered by Algolia</p>
            <div className="container">
              <PostsList searchQuery={searchQuery} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default PostsSearch
