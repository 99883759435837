import { CONTACT_TAB, ROUTES } from "../../../constants.js"
import React, { useState, useRef } from "react"


import BlueBook from "../../images/icons/blue-book.svg"
import BookModal from "./e-book-modal.js"
import Cross from "../../images/icons/cross.svg"
import Facebook from "../../images/social/facebook.svg"
import Instagram from "../../images/social/instagram.svg"
import { Link } from "gatsby"
import Linkedin from "../../images/social/linkedin.svg"
import { OptionDuTheme } from "../../hooks/site-options"
import Pinterest from "../../images/social/pinterest.svg"
import ScrollAnimation from "react-animate-on-scroll"
import Wave from "../decoration/wave.js"

import ArrowRight from "../../images/icons/arrow-right.svg"

const Footer = () => {
  const [email, setEmail] = useState("")
  const [formSubmited, setFormSubmited] = useState(false)
  const options = OptionDuTheme()
  const texts = options.optionsDuTheme.options_content
  const [isVisible, setIsVisible] = useState(false)
  const [noErrorMail, setNoErrorMail] = useState(null)
  const botInput = useRef(null);

  const checkMail = () => {
    if(!!email){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
         return false;
      }
      return true;
    } 

    return false;
  }

  const onSubmit = e => {
    e.preventDefault()
    const formEl = e.target
    
    // fetch("/", {
    //   method: "POST",
    //   body: new FormData(formEl),
    // })
    //   .then(response => {
    //     if (response.ok) {
    //       setFormSubmited(true)
    //     }
    //   })
    //   .catch(error => {
    //     console.warn(error)
    //   })

    // 13861
    
    // setNoErrorMail(checkMail());

    if(botInput.current.value === ''){
      fetch("https://funky.buddybuddy.io/wp-json/contact-form-7/v1/contact-forms/13861/feedback", {
        method: "POST",
        body: new FormData(formEl),
      })
        .then(response => {
          console.log(response);
          if (response.ok) {
            setFormSubmited(true)
          }
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  return (
    <>
      {/* Debut modale wrapper : 
      Fix form eBook => Ajout du wrapping html de modal JS en dur
      pour sortir de la mecanique classique des modales
      pour rendre compatible Netlify forms */}
      <div
        className={"modal-overlay"}
        role="button"
        aria-label="Fermer la modale"
        tabIndex={-1}
        onKeyDown={() => setIsVisible(false)}
        onClick={() => setIsVisible(false)}
        style={{ display: isVisible ? "block" : "none" }}
      ></div>
      <div
        className={"modal-wrapper modal-wrapper--eBook"}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className={"modal-content modal-content--eBook"}>
          <button
            className={"modal-close"}
            onClick={() => setIsVisible(false)}
            title={"Fermer"}
          >
            <span>Fermer</span>
            <img src={Cross} alt={"X"} width="42" height="42" />
          </button>
          <BookModal texts={texts} />
        </div>
      </div>
      {/* Fin modale wrapper */}

      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <div className={"e-book"}>
          <img src={BlueBook} alt={"Blue book icon"} />
          <h2 dangerouslySetInnerHTML={{ __html: texts.eBook.title }} />
          <p dangerouslySetInnerHTML={{ __html: texts.eBook.text }} />
          <button
            onClick={() => setIsVisible(true)}
            title={texts.eBook.buttonTitle}
          >
            {texts.eBook.buttonTitle}
          </button>
        </div>
      </ScrollAnimation>

      <footer className={"footer"}>
        <Wave color="#ffffff" position="top" />
        <div className={"container"}>
          <div className={"newsletter"}>
            <h3 dangerouslySetInnerHTML={{ __html: texts.newsletter.title }} />
            <p dangerouslySetInnerHTML={{ __html: texts.newsletter.text }} />
            {/* 
              Form newsletter
            */}
            {/* <div className={"newsletter-iframe"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/2O0S/FVw" width="100%"></iframe>
                  <script type="text/javascript" src="https://app.mailjet.com/statics/js/iframeResizer.min.js"></script>
              `,
                }}
              />
            </div> */}
            <form
              type={"submit"}
              name="newsletter-form"
              autoComplete="off"
              onSubmit={e => onSubmit(e)}
            >
              <div hidden>
                <label>
                  Don’t fill this out:
                  <input ref={botInput} name="bot-field" />
                </label>
              </div>
              <div className={"input-wrapper"}>
                {formSubmited ? (
                  <p className="confirm">Demande reçue.</p>
                ) : (
                  <>
                    <input
                      type="email"
                      name="your-email"
                      placeholder={"funky@exemple.com"}
                      value={email}
                      required
                      autoComplete="off"
                      onChange={e => setEmail(e.target.value)}
                    />
                    <input
                      hidden
                      id="subscribe"
                      type="checkbox"
                      name="mailjet-opt-in"
                      defaultChecked={true}
                      autoComplete="off"
                    />
                    <button type={"submit"}>
                      <img
                        src={ArrowRight}
                        role={"presentation"}
                        alt={"Arrow"}
                      />
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
          <div className={"contact"}>
            <h4>CONTACT & PRESSE</h4>
            <ul>
              <li>
                <Link to={`${ROUTES.CONTACT}${CONTACT_TAB.SERVICE}`}>
                  Contact
                </Link>
              </li>
              <li>
                <Link to={ROUTES.JOIN}>Nous rejoindre</Link>
              </li>
              <li>
                <Link to={`${ROUTES.CONTACT}${CONTACT_TAB.SELL}`}>
                  Devenir revendeur
                </Link>
              </li>
              <li>
                <a href={texts.pressKit} title={"Press Kit"}>
                  Press Kit
                </a>
              </li>
            </ul>
          </div>
          <div className={"service"}>
            <h4>SERVICE CLIENT</h4>
            <ul>
              <li>
                <Link to={ROUTES.FAQ}>FAQ</Link>
              </li>
              <li>
                <Link to={`${ROUTES.FAQ}#0`}>Livraison</Link>
              </li>
              <li>
                <Link to={`${ROUTES.FAQ}#2`}>Paiement sécurisé</Link>
              </li>
              <li>
                <Link to={ROUTES.POLICY}>Mentions légales</Link>
              </li>
              <li>
                <Link to={ROUTES.CGV}>CGV</Link>
              </li>
            </ul>
          </div>
          <div className={"social"}>
            <h4>SUIVEZ-NOUS</h4>
            <ul>
              {!!texts.socials.instagram && (
                <li>
                  <a
                    href={texts.socials.instagram}
                    target={"blank"}
                    className={"social-links"}
                  >
                    <img
                      src={Instagram}
                      alt={"Instagram"}
                      width="24"
                      height="25"
                    />
                  </a>
                </li>
              )}
              {!!texts.socials.facebook && (
                <li>
                  <a
                    href={texts.socials.facebook}
                    target={"blank"}
                    className={"social-links"}
                  >
                    <img
                      src={Facebook}
                      alt={"Facebook"}
                      width="24"
                      height="25"
                    />
                  </a>
                </li>
              )}
              {!!texts.socials.pinterest && (
                <li>
                  <a
                    href={texts.socials.pinterest}
                    target={"blank"}
                    className={"social-links"}
                  >
                    <img
                      src={Pinterest}
                      alt={"Pinterest"}
                      width="24"
                      height="25"
                    />
                  </a>
                </li>
              )}
              {!!texts.socials.linkedin && (
                <li>
                  <a
                    href={texts.socials.linkedin}
                    target={"blank"}
                    className={"social-links"}
                  >
                    <img
                      src={Linkedin}
                      alt={"LinkedIn"}
                      width="24"
                      height="25"
                    />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <p className={"rights"}>
          © {new Date().getFullYear()} FunkyVeggie - All Rights Reserved
        </p>
      </footer>
    </>
  )
}

export default Footer
