import React, { useState, useEffect } from "react"

/*
|--------------------------------------------------------------------------
| DEBOUNCING
|--------------------------------------------------------------------------
*/
export default (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value])
  return debouncedValue
}
