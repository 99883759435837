import { add } from 'lodash';
import React from 'react';

const Img = ({source, alt, title, classe, width, style}) => {

    let src = null;

    if(source || source !== '' || source !== null){
        
        if(!source.includes('pack_découverte_01')){ // quick fix opé Lena situation
            src = source.replace('https://funky.buddybuddy.io/wp-content/uploads/', 'https://cdn.funkyveggie.fr/wp-content/uploads/');
        } else {
            src = source;
        }
        
    }

    let additionnal = '';
    if(width !== null) {
        additionnal = '&width='+width
    }
    
    return (
        <picture alt={alt} title={title} className={classe} style={style}>
            <source srcset={src+'?format=webp'+additionnal} type="image/webp" />
            <img src={src+'?'+additionnal} alt={alt || "Image - Funkyveggie"} />
        </picture>
    )
}

export default Img;

Img.defaultProps = {
    source: null,
    alt: null,
    title: null,
    classe: null,
    width: null,
    style: null,
}