import { Link } from "gatsby"
import { ROUTES } from "../../../../constants.js"
import React from "react"

const Subnav = () => {
  return (
    <div className={"sub-nav"}>
      <div className="container">
        <Link
          to={ROUTES.FAQ}
          title={"FAQ"}
          activeClassName="active"
        >
          FAQ
        </Link>
        <Link
          to={ROUTES.CGV}
          title={"Conditions générales de vente"}
          activeClassName="active"
        >
          Conditions générales de vente
        </Link>
        <Link
          to={ROUTES.POLICY}
          title={"Mentions légales"}
          activeClassName="active"
        >
          Mentions légales
        </Link>
      </div>
    </div>
  )
}

export default Subnav
