import React, { useContext, useState, useEffect } from "react"

import ImagePackNav from "../../images/nav/image-pack-nav-600-2.jpg"
import CartIcon from "../../images/icons/shopping-cart.svg"
import ChevronIcon from "../../images/icons/chevron-down.svg"
import EcoLogo from "./eco-logo/eco-logo.js"
import Img from "../../helpers/img"
import { Link } from "gatsby"
import Logo from "../../images/funky-veggie-logo-new.svg"
import MenuIcon from "../../images/icons/menu.svg"
import { OptionDuTheme } from "../../hooks/site-options"
import PostsSearch from "./../posts-search/posts-search.js"
import { ROUTES } from "../../../constants"
import StoreContext from "~/context/StoreContext"
import SubNav from "../../components/nav/sub-nav/sub-nav.js"
import cn from "classnames"
import normalizePath from "../../helpers/normalizePath"
import useDocumentScrollThrottled from "../../helpers/hooks/useDocumentScrollThrottled.js"
import useWindowSize from "../../helpers/hooks/useWindowSize"
import { size } from "lodash"

export default function Nav({ isWhite, location }) {
  const MINIMUM_SCROLL = 300
  const TIMEOUT_DELAY = 50
  const [shouldHideNav, setShouldHideNav] = useState(false)
  const [scrollY, setScrollY] = useState(false)
  const [showImages, setShowImages] = useState(false)

  const options = OptionDuTheme()
  const headerNav = options.optionsDuTheme.options_content.headerNav

  useDocumentScrollThrottled(({ previousScrollTop, currentScrollTop }) => {
    setScrollY(currentScrollTop)
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL / 10
    setTimeout(() => {
      setShouldHideNav(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  const sizes = useWindowSize()
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false)
  const [navItemsIsOpen, setNavItemsIsOpen] = useState(false)
  const {
    isCartOpen,
    setIsCartOpen,
    store: {
      isPromoBannerOpen,
      checkout: { lineItems },
    },
  } = useContext(StoreContext)

  const shouldDisplaySubNav = pathname =>
    [ROUTES.FAQ, ROUTES.CGV, ROUTES.POLICY].includes(normalizePath(pathname))

  const productsNumber = lineItems.length

  useEffect(() => {
    if (sizes.isMobile && dropDownIsOpen) {
      setShowImages(true)
    } else if (sizes.isMobile && !dropDownIsOpen) {
      setShowImages(false)
    }

    if (!sizes.isMobile) {
      setShowImages(true)
    }

    if (sizes.isMobile === undefined) {
      setShowImages(false)
    }
  }, [sizes.isMobile, dropDownIsOpen])

  return (
    <>
      {!sizes.isMobile && (
        <EcoLogo
          isWhite={location.pathname === ROUTES.HOME && !sizes.isMobile}
        />
      )}
      <nav
        className={cn(
          "nav",
          { "nav--promo": isPromoBannerOpen },
          { "nav--mobile": sizes.isMobile },
          { "nav--desktop": !sizes.isMobile },
          { "nav--scrolling": scrollY > MINIMUM_SCROLL },
          { "nav--up": shouldHideNav },
          { "nav--white": isWhite || sizes.isMobile },
          { "nav--black": !isWhite && !sizes.isMobile }
        )}
      >
        <Link
          className={"nav__logo"}
          title={"Retourner à l'accueil"}
          to={ROUTES.HOME}
        >
          <img src={Logo} alt="Funky Veggie Logo" width="74" height="95" />
        </Link>

        {sizes.isMobile && (
          <EcoLogo
            isWhite={location.pathname === ROUTES.HOME && !sizes.isMobile}
          />
        )}

        <ul
          className={cn("nav__items", {
            "nav__items--open": sizes.isMobile && navItemsIsOpen,
          })}
        >
          <li className={"nav__item"}>
            <Link
              className={"nav__link"}
              to={ROUTES.SHOP}
              onClick={e => {
                sizes.isMobile && e.preventDefault()
                setDropDownIsOpen(!dropDownIsOpen)
              }}
              activeClassName="active"
              partiallyActive={true}
            >
              Shop{" "}
              <img
                src={ChevronIcon}
                className={cn("nav__link__chevron", {
                  "nav__link__chevron--rotated":
                    sizes.isMobile && dropDownIsOpen,
                })}
                alt=""
              />
            </Link>
            <div
              className={cn("nav__dropdown", {
                "nav__dropdown--open": sizes.isMobile && dropDownIsOpen,
              })}
            >
              <ul className={"nav__products"}>
                {!!headerNav.productsNav.cats &&
                  headerNav.productsNav.cats.map((item, i) =>
                    !item.isPack ? (
                      <li className={"nav__product"} key={i}>
                        <Link
                          to={ROUTES.SHOP + "/" + item.cat.slug}
                          activeClassName="active"
                          partiallyActive={true}
                        >
                          {!!item.cat &&
                            !!item.cat.gamme_content &&
                            !!item.cat.gamme_content.productImage &&
                            !!item.cat.gamme_content.productImage.sourceUrl &&
                            showImages && (
                              <Img
                                source={
                                  item.cat.gamme_content.productImage.sourceUrl
                                }
                                width={sizes.isMobile ? 80 : 300}
                                alt={
                                  "Funky Veggie - Gamme " +
                                  (item?.cat?.name || "") +
                                  " " +
                                  (item?.cat?.description || "")
                                }
                              />
                            )}
                          <strong>{item.cat.name}</strong>
                          <span>{item.cat.description}</span>
                        </Link>
                      </li>
                    ) : (
                      <li className={"nav__product nav--pack"} key={i}>
                        <Link
                          to={ROUTES.SHOP + "/" + item.cat.slug}
                          activeClassName="active"
                          partiallyActive={true}
                        >
                          {!!item.cat &&
                            !!item.cat.gamme_content &&
                            !!item.cat.gamme_content.productImage &&
                            !!item.cat.gamme_content.productImage.sourceUrl &&
                            showImages && (
                              <div className="imgWrapper">
                                <Img
                                  source={
                                    ImagePackNav ||
                                    item.cat.gamme_content.productImage
                                      .sourceUrl
                                  }
                                  width={sizes.isMobile ? 80 : 600}
                                  alt={
                                    "Funky Veggie - Gamme " +
                                    (item?.cat?.name || "")
                                  }
                                />
                              </div>
                            )}
                          <span>Packs JUSQU’A -20%</span>
                        </Link>
                      </li>
                    )
                  )}
              </ul>
              <Link
                className={"nav__link--all"}
                to={ROUTES.SHOP}
                activeClassName="active"
                partiallyActive={true}
              >
                <span>Tous les produits</span>
              </Link>
            </div>
          </li>
          <li className={"nav__item"}>
            <Link
              className={"nav__link"}
              to={ROUTES.ADVENTURE}
              activeClassName="active"
            >
              Funky aventure
            </Link>
          </li>
          <li className={"nav__item"}>
            <Link
              className={"nav__link"}
              to={ROUTES.BLOG}
              activeClassName="active"
              partiallyActive={true}
            >
              Blog & Podcasts
            </Link>
          </li>
          <li className={"nav__item"}>
            <Link
              className={"nav__link"}
              to={ROUTES.LOCATOR}
              activeClassName="active"
            >
              Nous trouver
            </Link>
          </li>
        </ul>
        <PostsSearch isWhite={isWhite && !sizes.isMobile} />
        <button
          className={cn("nav__menu-trigger")}
          onClick={() => setNavItemsIsOpen(!navItemsIsOpen)}
        >
          <img src={MenuIcon} alt="" />
        </button>
        <button
          className={cn("nav__cart-trigger nav__link", {
            "nav__cart-trigger--desktop": !sizes.isMobile,
          })}
          onClick={() => setIsCartOpen(!isCartOpen)}
        >
          {sizes.isMobile ? (
            <>
              <img src={CartIcon} alt="" width="24" height="24" />
              {productsNumber > 0 && <sup>{productsNumber}</sup>}
            </>
          ) : (
            <span>Panier&nbsp;({productsNumber})</span>
          )}
        </button>

        {shouldDisplaySubNav(location.pathname) && (
          <SubNav location={location} />
        )}
      </nav>
    </>
  )
}
