import { Link } from "gatsby"
import React from "react"
import Img from "../../../helpers/img"

const ProductCard = ({
  product: {
    productColor,
    productId,
    productImage,
    productName,
    variantName,
    uri,
    slug,
  },
}) => {
  return (
    <div key={productId} className="product-card">
      <Link to={uri}>
        <div
          className="product-card__imageWrapper"
          style={{ backgroundColor: productColor }}
        >
          {!!productImage &&
            !!productImage.sourceUrl && (
              <Img 
                source={productImage.sourceUrl}
                width={400}
                classe="product-card__image"
                alt={productImage?.altText ? productImage?.altText : "Funky Veggie - Produit - " + (productName || ' ') + (variantName || ' ') }
              />
            )}
        </div>
        <h3 className="product-card__name">{productName}</h3>
        <p className="product-card__variantName">{variantName}</p>
      </Link>
    </div>
  )
}

export default ProductCard
