import React, { useContext, useState, useEffect } from "react"

import { Link } from "gatsby"
import { ROUTES } from "../../../../../constants.js"
import StoreContext from "~/context/StoreContext"
import useDebounce from "../../../../helpers/hooks/useDebounce.js"

const LineItem = props => {
  // Data
  const { item } = props
  const [tempQty, setTempQty] = useState(item.quantity)
  const debouncedQty = useDebounce(tempQty, 666)

  // Context
  const {
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  // Handlers
  const handleAddQty = () => {
    setTempQty(tempQty < 99 ? tempQty + 1 : 99)
  }

  const handleRemQty = () => {
    setTempQty(tempQty > 0 ? tempQty - 1 : 0)
  }

  // Lifecycle
  useEffect(() => {
    updateLineItem(client, checkout.id, item.id, debouncedQty)
  }, [debouncedQty])

  useEffect(() => {
    if (item?.quantity) {
      setTempQty(parseInt(item.quantity) || 0)
    } else {
      setTempQty(0)
    }
  }, [item.quantity])

  return (
    <div className="line-item">
      <Link to={`${ROUTES.SHOP}/${item.variant.product.handle}/`}>
        {item.variant.image && (
          <div className="line-item__img">
            <img
              src={item.variant.image.src}
              alt={`${item.title} product shot`}
            />
          </div>
        )}
      </Link>
      <div className="line-item__description">
        <div className="line-item__title">
          <span className="name">{item.title}</span>
          <span className="subname">{item.variant.name}</span>
        </div>
        <span className="line-item__qty">
          <button className="line-item__qty__btn" onClick={handleRemQty}>
            -
          </button>
          <input
            className="line-item__qty__nb"
            type="text"
            value={tempQty}
            maxLength={2}
            pattern={"[0-9]+"}
            onKeyPress={e => {
              if (e.key === "Enter") {
                let currVal = e.target.value || ""
                currVal = currVal
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1")
                setTempQty(parseInt(currVal || "1"))
              }
            }}
            onBlur={e => {
              let currVal = e.target.value || ""
              currVal = currVal
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1")
              setTempQty(parseInt(currVal || "1"))
            }}
          />
          <button className="line-item__qty__btn" onClick={handleAddQty}>
            +
          </button>
        </span>
        {/* {console.log(item.variant)} */}
        <span className="line-item__price">
          {item.variant.price.amount}&nbsp;€{" "}
          {item.variant?.compareAtPrice?.amount ? (
            <span className="oldPrice">
              {`${item.variant.compareAtPrice.amount}€`}
            </span>
          ) : (
            ""
          )}
        </span>
      </div>
    </div>
  )
}

export default LineItem
