import Icon from "./eco-logo-icon.js"
import React from "react"
import { useModal } from "../../../helpers/hooks/useModal.js"
import { OptionDuTheme } from "../../../hooks/site-options";

const EcoLogo = ({ isWhite }) => {
  const { show, RenderModal } = useModal()
  const options = OptionDuTheme();
  const texts = options.optionsDuTheme.options_content.ecoconception;
  return (
    <div className={"eco-logo"}>
      <button onClick={show} title={"Découvrez les sites éco-conçus"}>
        <Icon isWhite={isWhite} />
      </button>
      <RenderModal optClassName={"eco"}>
        {/* <h1>{texts.title}</h1> */}
        <div dangerouslySetInnerHTML={{ __html: texts.content }} />
      </RenderModal>
    </div>
  )
}

export default EcoLogo
